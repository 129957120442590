import { Dispatch } from 'redux';
import fetch from 'cross-fetch';
import types from '../types';
import queryString from "query-string";

export function receivedDriver({ drivers }: {drivers: any}) {
    if (drivers && drivers.length) {
        drivers = drivers.sort((a,b) => {
            if (a.id > b.id) return 1
            if (a.id == b.id) return 0
            return -1
        });
    }

    return {
        type: types.RECEIVE_DRIVER,
        drivers
    }
}

export function addDriver(drivers: {}, hash, urls, lastName) {
    return (dispatch: Dispatch<any, any>) => {
        const qs = queryString.parse(window.location.search);
        return fetch(`${urls.reservationsAPI}/reservations/${hash}/drivers?lastname=${lastName}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-API-KEY": qs['apiKey']
            },
            body: JSON.stringify(drivers)
        })
        .then(res => res.json())
        .then(json => {
            dispatch(receivedDriver(json))
        });
    }
}
