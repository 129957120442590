import {Dispatch} from 'redux';
import fetch from 'cross-fetch';
import types from '../types';
import queryString from 'query-string';

export function requestReservation(isFetching = true, error: any = false) {
  return {
    type: types.REQUEST_RESERVATION,
    reservation: [],
    status: error,
    isFetching,

  };
}

export function isFetching() {
  return {
    type: types.REQUEST_RESERVATION,
    isFetching: true,

  };
}

export function receiveReservation(reservation: { id: number, drivers: any[] }) {
  if (reservation.drivers && reservation.drivers.length) {
    reservation.drivers = reservation.drivers.sort((a, b) => {
      if (a.id > b.id) return 1
      if (a.id == b.id) return 0
      if (a.id < b.id) return -1
    });
  }

  return {
    type: types.RECEIVE_RESERVATION,
    reservation,
    isFetching: false,
  };
}

export function receiveModifyReservation(reservation: {}) {
  return {
    type: types.RECEIVE_RESERVATION,
    reservation,
    isFetching: false,
  };
}

export function statusModifyReservation(error: {}) {
  return {
    type: types.RECEIVE_RESERVATION,
    status: error,
    reservation: [],
    isFetching: false,
  };
}


export function confirmReservation(res: {}) {
  return {
    type: types.CONFIRM_RESERVATION,
    status: res,
    isFetching: false,
  };
}

export function createReservation(reservation: any, conf, reservationData: any) {
  return (dispatch: Dispatch<any, any>) => {
    dispatch(requestReservation());
    const qs = queryString.parse(window.location.search);
    return fetch(`${conf.urls.reservationsAPI}/reservations`, {
      method: 'POST',
      body: JSON.stringify(reservation),
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": `${qs['apiKey'] || conf.skyscannerId || conf.apiKeys.reservationsAPI}`
      },
    })
      .then(res => {
        const json = res.json();
        if (res.status >= 400) {
          return json.then(Promise.reject.bind(Promise));
        }
        return json;
      })
      .then(json => {
        fetch(`${conf.urls.reservationsAPI}/reservations/${json.reservationNumber}?lastname=${json.lastName}`, {
          method: 'GET',
          headers: {
            "X-API-KEY": qs['apiKey']
          }
        })
            .then((reservationDetails) => {
              reservationDetails.json().then((v) => {
                reservationData.value = Number(v.pricing.payNow ? v.pricing.payNow.totalAmount.toFixed(2) : v.pricing.payOnPickup.totalAmount.toFixed(2));
                reservationData.transaction_id = json.reservationNumber;
                (window as any).dataLayer.push({ecommerce: null});
                (window as any).dataLayer.push({
                  event: 'purchase',
                  ecommerce: reservationData
                })
              });
        })
        dispatch(confirmReservation(json));
      })
      .catch(res => dispatch(requestReservation(false, 'error')));

  };
}

export function fetchReservation(hash: string, urls, lastname?: string) {
  return (dispatch: Dispatch<any, any>) => {
    dispatch(requestReservation());
    const qs = queryString.parse(window.location.search);
    const lastNameQuery = lastname || qs[ 'lastname' ];
    return fetch(`${urls.reservationsAPI}/reservations/${hash}?lastname=${lastNameQuery}`, {
      method: 'GET',
      headers: {
        "X-API-KEY": qs['apiKey']
      }
    })
      .then(res => res.json())
      .then(json => {
        dispatch(receiveReservation(json))
      });
  }
}

export function fetchModifyReservation(data: any, conf) {
  return (dispatch: Dispatch<any, any>) => {
    dispatch(requestReservation());
    const qs = queryString.parse(window.location.search);
    return fetch(`${conf.urls.reservationsAPI}/modify-reservation/info/${data.reservationNumber}?lastname=${data.lastName}`, {
      method: 'GET',
      headers: {
        "X-API-KEY": qs['apiKey'] || conf.apiKeys.reservationsAPI
      }
    })
      .then(res => {
        const json = res.json();
        if (res.status >= 400) {
          return json.then(Promise.reject.bind(Promise));
        }
        return json;
        ;
      })
      .then(json => {
        dispatch(receiveModifyReservation(json))
      }).catch(err => {
        dispatch(statusModifyReservation('error_missing'))
      });
  }
}

export function putModifyReservation(id, data: {}, conf) {
  return (dispatch: Dispatch<any, any>) => {
    dispatch(isFetching());
    const qs = queryString.parse(window.location.search);
    return fetch(`${conf.urls.reservationsAPI}/modify-reservation/modify/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-API-KEY": qs['apiKey'] || conf.apiKeys.reservationsAPI
      },
    })
      .then(res => {
        const json = res.json();
        if (res.status >= 400) {
          return json.then(Promise.reject.bind(Promise));
        }
        return json;
      })
      .then(json => {
        dispatch(statusModifyReservation('success'))
      }).catch(err => {
        dispatch(statusModifyReservation('error_general'))
      });
  }
}

export const postRetryPayment = (reservationNumber, requestBody, conf) => {
  return dispatch => {
    dispatch(requestReservation());
    const qs = queryString.parse(window.location.search);
    return fetch(`${conf.urls.reservationsAPI}/payment/retry/${qs['apiKey'] || conf.apiKeys.reservationsAPI}/${reservationNumber}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then(res => {
        const json = res.json();
        if (res.status >= 400) {
          return json.then(Promise.reject.bind(Promise));
        }
        return json;
      })
      .then(json => {
        dispatch(confirmReservation(json))
      })
      .catch(err => dispatch(requestReservation(false, true)));
  }
}

export const cancelReservation = () => dispatch => dispatch(requestReservation(false));
