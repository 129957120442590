import React, {Component} from 'react';
import Modal from 'react-modal';
import {t} from '../conf/gettext';
import {NavBar} from '../components/NavBar';
import {SearchBar} from '../components/SearchBar';
import {Collapse} from '../components/Collapse';
import {ActiveListing} from '../components/ActiveListing';
import {RouteComponentProps} from 'react-router-dom';
import {Loader} from '../components/Loader';
import {getCurrencyOrDefault, getLocaleOrDefault} from '../helpers';
import queryString from 'query-string';
import Footer from '../components/Footer';
import SkyScanner from '../helpers/skyscanner';
import TagManager from 'react-gtm-module';

const initialState = {
  errors: {}
};
type State = Readonly<typeof initialState>

Modal.setAppElement('#app')

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export default class Booking extends Component<RouteComponentProps<any>, State> {
  readonly state: State = initialState;
  reservationNumber: string | string[] = ''
  lastName: string | string[] = ''

  constructor(props) {
    super(props);

    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    const qs = queryString.parse(this.props.location.search);
    if (qs['reservationNumber']) {
      this.reservationNumber = qs['reservationNumber']
    }
    if (qs['lastname']) {
      this.lastName = qs['lastname']
    }
    if (qs['currency']) {
      this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
    }

    this.accomodationName = React.createRef();
    this.pickupTime = React.createRef();
    this.numPassengers = React.createRef();
  }

  componentDidMount() {
    this.fetchReservation(this.props);
    document.title = t('Confirmation') + ' | ' + this.props.prismic.data.meta_title;
  }

  componentWillUnmount() {
    document.title = this.props.prismic.data.meta_title;
  }

  componentWillReceiveProps(props) {
    if (!this.props.hotel.hotelBooked && props.hotel.hotelBooked) {
      this.setState({hotelModalOpen: true});
    }

    if (this.props.reservation && !this.props.reservation.drivers && props.reservation.drivers && props.reservation.drivers.length) {
      const drivers = props.reservation.drivers.map((driver) => {
        driver.noExpiry = driver.expiry === 'noExp';
        return driver;
      });
      this.setState({drivers});
      this.lastName = props.reservation.lastName;

      if (SkyScanner.isToPush(props.reservation.reservationNumber)) {
        SkyScanner.pushTracking(SkyScanner.getCookie(), props.reservation, props.baseConf);
      }

      const payNow = props.reservation.pricing.payNow ? props.reservation.pricing.payNow.totalAmount : 0
      const payOnPickup = props.reservation.pricing.payOnPickup ? props.reservation.pricing.payOnPickup.totalAmount : 0
      if (localStorage.getItem('_reservation_nr') !== props.reservation.reservationNumber) {
        const tagManagerArgs = {
          dataLayer: {
            reservation: {
              reservationNumber: props.reservation.reservationNumber,
              paidNow: payNow,
              payableOnPickup: payOnPickup,
              sum: payNow + payOnPickup
            },
            event: 'reservation_created'
          }
        };
        TagManager.dataLayer(tagManagerArgs);
      }
      localStorage.setItem('_reservation_nr', props.reservation.reservationNumber);
    }
    if (!this.state.fetchedRate && props && Object.keys(props.baseConf).length) {
      this.props.fetchReservation(this.reservationNumber, props.baseConf.urls, this.lastName);
      this.setState({fetchedRate: true});
    }
  }

  fetchReservation(props) {
    if (!this.state.fetchedRate && props.baseConf && Object.keys(props.baseConf).length) {
      this.props.fetchReservation(this.reservationNumber, props.baseConf.urls, this.lastName);
      this.setState({fetchedRate: true});
    }
  }

  toggleHotelModal() {
    this.setState({hotelModalOpen: !this.state.hotelModalOpen});
  }

  createTimeOptions() {
    let timeOptions = [];

    for (let i = 0; i < 24; i++) {
      for (let k = 0; k < 60; k += 30) {
        const time = `${(i < 10 ? '0' : '') + i}:${(k < 10 ? '0' : '') + k}`;
        timeOptions.push(<option key={time} value={time}>{time}</option>)
      }
    }

    return timeOptions
  }

  bookHotel() {
    const errors = {};

    if (!this.accomodationName.current.value) {
      errors['accomodationName'] = true;
    }

    if (isNaN(this.numPassengers.current.value) || this.numPassengers.current.value <= 0) {
      errors['numPassengers'] = true;
    }

    this.setState({errors});

    if (!Object.keys(errors).length) {
      this.props.bookHotel({
        to_email: this.props.conf.supplier_email_hotel_delivery,
        reservationNumber: props.reservation.reservationNumber,
        pickup_time: this.pickupTime.current.value,
        accommodation: this.accomodationName.current.value,
        passengers: Number(this.numPassengers.current.value),
      }, this.props.baseConf.urls);
    }
  }

  getMarketingBody(text) {
    let __html = '';
    let isList = false;
    text.forEach(t => {
      if (t.type == 'paragraph') {
        if (isList) {
          isList = false;
          __html += '</ul>';
        }
        ;

        __html += `<p>${t.text}</p>`;
      } else if (t.type == 'list-item') {
        if (!isList) {
          isList = true;
          __html += '<ul>';
        }
        ;

        __html += `<li>${t.text}</li>`;
      }
    })

    return {__html};
  }

  render() {
    if (!this.props.reservation || !Object.keys(this.props.reservation).length || !(this.props.prismic && this.props.prismic.data) || !this.props.baseConf) return <Loader/>;

    return (
      <div>
        <NavBar prismic={this.props.prismic} setCurrency={this.props.setCurrency} setLocale={this.props.setLocale}
                currency={this.props.currency} exchange={this.props.exchange} locale={this.props.locale}
                conf={this.props.conf} history={this.props.history} match={this.props.match}/>
        <div className="disclaimer">
          <h3>{this.props.reservation.reservationStatus == 'PENDING' ? t('Booking pending') : t('Booking Confirmed')}</h3>
          <p>
            {this.props.reservation.reservationStatus == 'PENDING' ? this.props.prismic.data.confirmation_pending_message[0].text : this.props.prismic.data.confirmation_confirmed_message[0].text}
          </p>
          <div className="prices">
            <div className="price-item">
              {this.props.reservation.pricing.payNow ? this.props.reservation.pricing.payNow.totalAmount : 0} €
              <div className="description">
                {t('Paid now')}
              </div>
            </div>
            <div className="price-item">
              {this.props.reservation.pricing.payOnPickup ? this.props.reservation.pricing.payOnPickup.totalAmount : 0} €
              <div className="description">
                {t('Payable on pickup')}
              </div>
            </div>
            <div className="price-item">
              {this.props.reservation.reservationNumber}
              <div className="description">
                {t('Reservation #')}
              </div>
            </div>
          </div>
        </div>
        <SearchBar baseConf={this.props.baseConf} rateInfo={this.props.reservation} path={this.props.match.path}
                   history={this.props.history} reservation={this.props.reservation} desks={this.props.desks}
                   fetchRates={this.props.fetchRates} locale={this.props.locale} conf={this.props.conf}
                   location={this.props.location}/>
        <ActiveListing
          baseConf={this.props.baseConf}
          match={this.props.match}
          prismic={this.props.prismic}
          rateInfo={this.props.reservation}
          path={this.props.match.path}
          exchange={this.props.exchange}
          currency={this.props.currency}
          items={this.props.items}
          locale={this.props.locale}
          addDriver={this.props.addDriver}
          carinfo={this.props.carinfo}
        />
        <div className="flex-row wrap">
          {
            this.props.conf.enable_hotel_delivery == 'true' && (
              <div className="flex1_0_48 marginbottom32 margin0_8">
                <div className="margin16_0 text-blue text-bold text-lg">{t('Book a hotel pick-up')}</div>
                {!!this.props.prismic.data.book_hotel_pickup_description && <div
                    className="margin16_0 text-md text-dark-gray">{this.props.prismic.data.book_hotel_pickup_description}</div>}
                <div className="input-group">
                  <div className="row-item">
                    <div
                      className={`label ${this.state.errors.accomodationName ? 'has-error' : ''}`}>{t('Accomodation name')}</div>
                    <input ref={this.accomodationName}
                           className={`input minwidth100 ${this.state.errors.accomodationName ? 'has-error' : ''}`}
                           placeholder={t('Enter the name of your place of stay ...')}/>
                  </div>
                </div>
                <div className="input-group">
                  <div className="row-item">
                    <div
                      className={`label ${this.state.errors.pickupTime ? 'has-error' : ''}`}>{t('Requested pick-up time')}</div>
                    <select defaultValue='11:00' ref={this.pickupTime}
                            className={`input minwidth100 ${this.state.errors.pickupTime ? 'has-error' : ''}`}>
                      {this.createTimeOptions()}
                    </select>
                  </div>
                  <div className="row-item">
                    <div
                      className={`label ${this.state.errors.numPassengers ? 'has-error' : ''}`}>{t('Number of passengers')}</div>
                    <input ref={this.numPassengers}
                           className={`input minwidth100 ${this.state.errors.numPassengers ? 'has-error' : ''}`}
                           placeholder="0"/>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="flex1">
                    <button className="btn btn-green" onClick={() => this.bookHotel()}>
                      {this.props.hotel.isFetching ? <div className="spinner green"></div> : t('Book a pick-up')}
                    </button>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="flex1 fine-print margin12">
                    {this.props.hotel.error ? <span
                      className="text-red">{t('Unable to book hotel pick-up, please try again later')}</span> : t('A confirmation message will be sent to the renter’s e-mail address')}
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          this.props.prismic.data.confirmation_marketing_texts.map((text, i) => {
            return (<Collapse key={i} text={text}/>);
          })
        }
        <Modal
          isOpen={this.state.hotelModalOpen}
          onRequestClose={() => this.toggleHotelModal()}
          style={Object.assign({}, modalStyles, {right: '50%'})}
          contentLabel="Hotel pick-up confirmed"
          setState={state => this.setState(state)}
        >
          <div className="modal">
            <div className="heading">
              <h2 className="text-blue">{t('Hotel pick-up request received')}</h2>
              <i onClick={() => this.toggleHotelModal()} className="icon icon-cross-light-gray"></i>
              <div className="margin32_0">
                {t('We have received your hotel pick-up request. We will let you know once it has been confirmed.')}
              </div>
              <div className="flex-row">
                <div className="flex1 marginright32">
                  <button onClick={() => this.toggleHotelModal()} className="btn btn-gray">{t('close')}</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Footer prismic={this.props.prismic}/>
      </div>
    );
  }
};
